@mixin menu-title{
  letter-spacing: 5px;
}

.title-bar-title{
  @include menu-title;
}

.sidebar{
  color: $light-gray;

  .title{
    background-color: black;
    background-color: map-get($foundation-palette, primary);

    h1{
      margin: 0;
      padding: 0;
      font-size: rem-calc(18);
      font-weight: bold;
    }

    a{
      display: block;
      color: inherit;
      padding: rem-calc(20) $global-padding;

      @include menu-title;
    }

    @include show-for(large);
  }

  .main-nav{
    border-top: 1px solid black;
    border-bottom: 1px solid black;

    a{
      padding: $global-padding;
      display: block;
      color: $light-gray;
    }

    ul{
      list-style: none;
      margin-left: 0;
    }

    li{
      font-weight: bold;
      font-size: .9em;
      border-left: 2px solid transparent;
      transition: all .3s;

      &.active{
        border-color: map-get($foundation-palette, primary);

        &,
        &:hover{
          background-color: #101010;
        }
      }

      &:hover{
        background-color: #1a1a1a;
      }
    }
  }

  .session-box{
    padding: $global-padding 0;

    @include grid-row;

    .admin-email{
      @include grid-column(8);

      text-overflow: ellipsis;
      overflow: hidden;
    }
    .sign-out{ @include grid-column(4); }
  }
}
